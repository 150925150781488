import React from "react"

import PortfolioItem from "./PortfolioItem";

import styles from './portfolioItems.css';

import cover from '../../assets/micro/cover.png';
import coverAqua from '../../assets/aqua/cover-aqua.png';
import coverPromyk from '../../assets/promyk/cover-promyk.png';


const items = [
    {
        imgSrc: cover,
        title: 'Micro',
        description: 'To make home office more like office',
        tags: ['UX Research', 'UI Design', 'Prototyping', 'User Testing', 'Axure'],
        link: '/portfolio/micro'
    },
    {
        imgSrc: coverAqua,
        title: 'Aqua Aerobik',
        description: 'Redesign of the website for purchasing passes for Aquaaerobics',
        tags: ['UX Research', 'UI Design', 'Figma', 'Refactor'],
        link: '/portfolio/aqua'
    },
    {
        imgSrc: coverPromyk,
        title: 'Promyk',
        description: 'Adoption made easier',
        tags: ['UX Research', 'UI Design', 'Figma', 'Own Project'],
        link: '/portfolio/promyk'
    },
    {
        imgSrc: 'https://s3.amazonaws.com/images.hamlethub.com/hh20mediafolder/2514/201406/iStock_000016476123Small.jpg',
        title: 'CovidVolunteer',
        description: 'To help people help people',
        tags: ['UX Research', 'Prototyping'],
        link: '/portfolio/covid'
    },
]

const PortfolioItems = () => (
    <div className="pis-root">
        {items.map(item => <PortfolioItem {...item} key={item.title} />)}
    </div>
)

export default PortfolioItems