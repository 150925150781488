import React from "react"

import Layout from "../components/Layout";

import PortfolioItems from "../components/index/PortfolioItems";
import Bio from "../components/index/Bio";

import './styles.css';

const IndexPage = () => {
  return (
    <Layout>
      <div style={{ background: '#fafafa' }}>
        <Bio />
        <PortfolioItems />
      </div>
    </Layout >
  )
}

export default IndexPage
