import React from 'react';

import NavBar from './NavBar';

const Layout = ({ children }) => (
    <div style={{ fontFamily: 'sans-serif' }}>
        <NavBar />
        <div>
            {children}
        </div>
    </div>
)

export default Layout;