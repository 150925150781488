import React, { useEffect, useState } from 'react';
import { navigate, Link } from 'gatsby'

import styles from './navBar.css';

const NavBar = () => {
    const [isPortfolioPage, setIsPortfolioPage] = useState(false)
    const [navHandler, setNavHandler] = useState(() => { })

    useEffect(() => {
        const isPortfolio = window.location.pathname.includes('portfolio');
        console.log('isPortfolio', isPortfolio);

        setIsPortfolioPage(isPortfolio);

        const goToHome = () => navigate('/');
        const scrollToPortfolio = () => {
            window.scrollTo({
                top: 500,
                behavior: 'smooth'
            });
        }

        setNavHandler(isPortfolio ? () => goToHome : () => scrollToPortfolio);

    }, []);

    return (
        <div className="nb-wrapper">
            <div className="nb-root">
                <Link to="/">
                    <div className="nb-person">
                        <div className="nb-name">Kinga Gryz</div>
                        <div>UX/UI Designer</div>
                    </div>
                </Link>

                <div className="nb-navItems">
                    <div onClick={navHandler} className="nb-navItem">
                        {isPortfolioPage ? 'Home' : 'Portfolio'}
                    </div>
                    <a href="mailto:kingagryz7@gmail.com" target="_blank" className="nb-navItem">
                        kingagryz7@gmail.com
                    </a>
                </div>
            </div>
        </div>
    )
}

export default NavBar;