import React from 'react';
import { Link } from 'gatsby';

import styles from './portfolioItem.css';

const PortfolioItem = ({
    imgSrc,
    title,
    description,
    tags,
    link,
}) => (
    <div className="pi-root">
        <img src={imgSrc} className="pi-image" />
        <div className="pi-textContainer">
            <div className="pi-tags" >
                {tags.map((tag, index) => <span>{tag + (index === tags.length - 1 ? '' : ' / ')}</span>)}
            </div>
            <h4 className="pi-header">{title}</h4>
            <div className="pi-description">{description}</div>
            <Link to={link}>
                <div className="pi-button">
                    View Case Study
                </div>
            </Link>
        </div>
    </div>
);

export default PortfolioItem;